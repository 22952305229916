<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row class="mb-5">
        <b-col cols="12">
          <b-form-checkbox v-model="modeSelected" name="check-button" class="mr-5 d-block" switch>
            <label class="checkMode"> </label>
            <label v-if="!modeSelected">Cochez pour choisir une plage de mois</label>
            <label v-else>Décochez pour specifier un mois</label>
          </b-form-checkbox>
        </b-col>
        <template v-if="modeSelected" class="selectedMonth">
          <label class="mt-1 mx-3">Definissez la plage :</label>
          <vue-monthly-picker
            v-model="selectedBeginMonth"
            :monthLabels=monthLabelsSelected
            dateFormat="MM-YYYY"
            placeHolder="Selectionnez un mois"
            :min="exploitationCreated"
            :max="currentMonthExploitable"
          >
          </vue-monthly-picker>
          <vue-monthly-picker
            v-model="selectedEndMonth"
            :monthLabels=monthLabelsSelected
            dateFormat="MM-YYYY"
            placeHolder="Selectionnez mois"
            :min="exploitationCreated"
            :max="currentMonthExploitable"
          >
          </vue-monthly-picker>
          <info-message class="mt-2 info-danger-month" v-if="!greatMonthState">
            Le second mois selectionné doit être supérieure ou égal au premier mois sélectionné
          </info-message>
        </template>
        <template v-else>
          <label class="mt-1 mx-3">Mois en cours :</label>
          <vue-monthly-picker
            v-model="currentMonth"
            :monthLabels=monthLabelsSelected
            dateFormat="MM-YYYY"
            placeHolder="mois en cours"
            :min="exploitationCreated"
            :max="currentMonthExploitable"
            class="selectedMonth"
          >
          </vue-monthly-picker>
        </template>
        <button-spinner variant="success" type="submit" class="ml-3 mr-5 mb-2" @click="findResume"
                        :fetching=fetchingFindResume title="Afficher le bilan">
          Afficher le bilan
        </button-spinner>
      </b-row>
      <b-row class="m-0 p-0">
        <b-card header-tag="header" footer-tag="footer" class="w-100">
          <div slot="header">
            <i class="icon-layers mr-2"></i>
            <strong>
              Bilan des entrees et depenses
            </strong>
            <div class="card-header-actions">
              <button-spinner variant="success" title="Exporter en Excel" class="float-right mr-2 mb-2 exportReport"
                              @click="exportFile"
                              :fetching="fetchingDownloadResume">
                <i class="fa fa-upload"></i>
              </button-spinner>
            </div>
          </div>
          <div class="px-2 w-100">
            <!-- Tabs with card integration -->
            <b-jumbotron v-if="dataReadyResume" class="">
              <div class="text-center w-100 px-5">
                <b-spinner></b-spinner>
                <br>
                <!--todo customizer tous les messages de chargement-->
                Chargement des informations...
              </div>
            </b-jumbotron>
            <b-card no-body :class="dataReadyResume ? 'd-none' : 'd-block'">
              <b-tabs card v-model="monthYearResume">
                <b-tab :title-item-class="!rangeMonths ? 'd-none' : ''">
                  <template slot="title">
                    <b-badge variant="primary" class="p-2">
                      {{rangeMonthsName}}
                      
                      <info-message class=""  v-if="monthYearResume ==0">
                        Cet onglet presente le bilan des dépenses et des entrées de {{rangeMonthsName}}
                      </info-message>
                    </b-badge>
                  </template>
                  <b-row class="p-1" style="overflow-y: auto!important;">
                    <div id="rangeSpreadsheet" ref="rangeSpreadsheet">
                    </div>
                  </b-row>
                </b-tab>
                <b-tab v-for="(tab,indice) in tabCounter" :active="!rangeMonths" title-item-class="p-2">
                  <template slot="title">
                    {{tab.tabTitle}}
                    <info-message class="" v-if="!rangeMonths || monthYearResume == indice+1">
                      Cet onglet presente le bilan des dépenses et des entrées de {{tab.tabTitle}}
                    </info-message>
                  </template>
                  <b-row class="p-1" style="overflow-y: scroll!important;">
                    <div :id="`spreadsheet${indice}`" :ref="`spreadsheet${indice}`">
                    </div>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </b-card>
      </b-row>
    </div>
  
  </div>
</template>

<script>
  import jexcel from 'jexcel'
  import 'jexcel/dist/jexcel.css'
  import {Api, moneyFormat} from "../../../helpers";
  import moment from 'moment'
  import {save} from 'save-file'
  import excelE from 'node-excel-export'
  
  export default {
    name: "AccountingResume",
    title: "PIA - Bilan",
    data() {
      return {
        resumeEntriesSpents: [],
        selectedBeginMonth: "",
        selectedEndMonth: "",
        currentMonth: moment(),
        modeSelected: false,
        monthYearResume: 1,
        afterModification: false,
        fetchingFindResume: false,
        fetchingDownloadResume: false,
        dataReadyResume: false,
        errorMessageSelectedDate: false,
        submittedRangeMonth: false,
        countTab: [],
        
      }
    },
    async created() {
      
      this.dataReadyResume = true
      await Api.get('/exploitation/capital/current-resume/', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.resumeEntriesSpents = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        
        })
    },
    mounted(){
    
    },
    updated() {
      if (this.jExcelOptions.length != 0) {
        for (let i = 0; i < this.resumeEntriesSpents.length; i++) {
          if (`spreadsheet${i}` in this.$refs && this.$refs[`spreadsheet${i}`][0].innerHTML == "") {
            this.dataReadyResume = false
            const jExcelObj = jexcel(this.$refs["spreadsheet" + i][0], this.jExcelOptions[i]);
            // Object.assign(this, jExcelObj); // pollutes component instance
            Object.assign(this, {jExcelObj});
          }
        }
      }
      if ("rangeSpreadsheet" in this.$refs && this.$refs["rangeSpreadsheet"].innerHTML == "") {
        console.log('nous sommes dans le bilan')
        console.log(this.dataRangeEntriesSpents)
        const jExcelObj = jexcel(this.$refs["rangeSpreadsheet"], this.jExcelRangeOptions);
        // Object.assign(this, jExcelObj); // pollutes component instance
        Object.assign(this, {jExcelObj});
      }
    },
    watch: {
      resumeEntriesSpents() {
      
      },
      afterModification() {
      
      }
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      exploitationNam() {
        return this.$store.getters.exploitationName
      },
      exploitationCreated() {
        return moment(this.$store.getters.exploitationDateCreated).subtract(1, 'months')
      },
      currentMonthExploitable() {
        return moment()
      },
      greatMonthState() {
        return !this.submittedRangeMonth || (this.selectedBeginMonth != "" && this.selectedEndMonth != ""
          && moment(this.selectedEndMonth).isSameOrAfter(moment(this.selectedBeginMonth)))
      },
      monthLabelsSelected() {
        return ['Jan', 'Feb', 'Mar',
          'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep',
          'Oct', 'Nov', 'Dec']
      },
      tabCounter() {
        return this.resumeEntriesSpents.map(resume => ({
          tabTitle: resume.month + " " + resume.year,
        }))
      },
      rangeMonths() {
        return this.resumeEntriesSpents.length >= 2
      },
      rangeMonthsName() {
        const reL = this.resumeEntriesSpents.length
        const month = this.resumeEntriesSpents[0].month
        const year = this.resumeEntriesSpents[0].year
        if (reL == 0)
          return ""
        if (reL == 1)
          return month + " " + year
        const monthL = this.resumeEntriesSpents[reL - 1].month
        const yearL = this.resumeEntriesSpents[reL - 1].year
        
        return month + " " + year + " - " + monthL + " " + yearL
      },
      dataEntriesSpents() {
        return this.resumeEntriesSpents.map(resumeEntrySpent => {
          let initialAmount = resumeEntrySpent.initialAmount
          let result = []
          
          // le nom de la ferme
          result.push([this.exploitationNam])
          result.push([])
          const month = resumeEntrySpent.month
          const year = resumeEntrySpent.year
          // le mois et l'annee
          result.push([
            "Mois :",
            "",
            "",
            month + " " + year,
          ])
          result.push([
            "Fiche completée par :",
            "",
            "",
            "Kratos Security",
          ])
          result.push([])
          result.push([
            "Date",
            "Entrées",
            "",
            "",
            "Date",
            "Dépenses",
            "",
            "",
            "Solde"
          ])
          result.push([
            "",
            "Nature",
            "Par",
            "Montant",
            "",
            "Nature",
            "Par",
            "Montant",
            "",
          ])
          result.push([
            "",
            "",
            "",
            "",
            "01 " + month + " " + year,
            "Solde initial",
            "",
            0,
            moneyFormat(initialAmount, 0, 3, ' '),
          ])
          
          let resultSpentsEntries = []
          resumeEntrySpent.resume.forEach(element => {
            const nbreSpents = element.spents.length
            const nbreEntries = element.entries.length
            const maxN = Math.max(nbreSpents, nbreEntries)
            for (let i = 0; i < maxN; i++) {
              let resultIntermediate = []
              if (i < nbreEntries) {
                const temp = element.entries[i]
                if (temp.amount) {
                  resultIntermediate.push(...[
                    element.date, temp.paymentMode.name + " " + temp.entryReceiver.name, temp.entryEmitter.name, moneyFormat(temp.amount, 0, 3, ' ')
                  ])
                  initialAmount += temp.amount
                } else {
                  resultIntermediate.push(...[
                    element.date, temp.name, temp.user.name, moneyFormat(temp.price, 0, 3, ' ')
                  ])
                  initialAmount += temp.price
                }
              } else resultIntermediate.push(...["", "", "", ""])
              if (i < nbreSpents) {
                const temp = element.spents[i]
                if (temp.spentEmitter) {
                  initialAmount -= temp.amount
                  resultIntermediate.push(...[
                    element.date, temp.name, temp.spentEmitter.name, moneyFormat(temp.amount, 0, 3, ' '), moneyFormat(initialAmount, 0, 3, ' ')
                  ])
                } else {
                  initialAmount -= temp.cost
                  resultIntermediate.push(...[
                    element.date, temp.name, "", moneyFormat(temp.cost, 0, 3, ' '), moneyFormat(initialAmount, 0, 3, ' ')
                  ])
                }
              } else {
                resultIntermediate.push(...["",
                  "",
                  "",
                  "",
                  moneyFormat(initialAmount, 0, 3, ' ')
                ])
              }
              resultSpentsEntries.push(resultIntermediate)
            }
          })
          
          result.push(...resultSpentsEntries)
          result.push([])
          result.push([
            "",
            "TOTAL ENTREES",
            "",
            moneyFormat(resumeEntrySpent.totalEntries, 0, 3, ' '),
            "",
            "TOTAL DEPENSES",
            "",
            moneyFormat(resumeEntrySpent.totalSpents, 0, 3, ' '),
            moneyFormat(initialAmount, 0, 3, ' ')
          ])
          result.push([])
          
          return result
        })
        
      },
      dataRangeEntriesSpents() {
        let finalResult = []
        // le nom de la ferme
        finalResult.push([this.exploitationNam])
        finalResult.push([])
        const reL = this.resumeEntriesSpents.length
        const month = this.resumeEntriesSpents[0].month
        const year = this.resumeEntriesSpents[0].year
        const monthL = this.resumeEntriesSpents[reL - 1].month
        const yearL = this.resumeEntriesSpents[reL - 1].year
        
        let initialAmount = this.resumeEntriesSpents[0].initialAmount
        // le mois et l'annee
        finalResult.push([
          "Mois :",
          "",
          "",
          month + " " + year + " - " + monthL + " " + yearL,
        ])
        
        finalResult.push([
          "Fiche completée par :",
          "",
          "",
          "Kratos Security",
        ])
        finalResult.push([])
        finalResult.push([
          "Date",
          "Entrées",
          "",
          "",
          "Date",
          "Dépenses",
          "",
          "",
          "Solde"
        ])
        finalResult.push([
          "",
          "Nature",
          "Par",
          "Montant",
          "",
          "Nature",
          "Par",
          "Montant",
          "",
        ])
        finalResult.push([
          "",
          "",
          "",
          "",
          "01 " + month + " " + year,
          "Solde initial",
          "",
          0,
          moneyFormat(initialAmount, 0, 3, ' '),
        ])
        let totalEntries = 0
        let totalSpents = 0
        for (let i = 0; i < this.resumeEntriesSpents.length; i++) {
          const resumeEntrySpent = this.resumeEntriesSpents[i]
          totalEntries += resumeEntrySpent.totalEntries
          totalSpents += resumeEntrySpent.totalSpents
          resumeEntrySpent.resume.forEach(element => {
            const nbreSpents = element.spents.length
            const nbreEntries = element.entries.length
            const maxN = Math.max(nbreSpents, nbreEntries)
            for (let i = 0; i < maxN; i++) {
              let resultIntermediate = []
              if (i < nbreEntries) {
                const temp = element.entries[i]
                if (temp.amount) {
                  resultIntermediate.push(...[
                    element.date, temp.paymentMode.name + " " + temp.entryReceiver.name, temp.entryEmitter.name, moneyFormat(temp.amount, 0, 3, ' ')
                  ])
                  initialAmount += temp.amount
                } else {
                  resultIntermediate.push(...[
                    element.date, temp.name, temp.user.name, moneyFormat(temp.price, 0, 3, ' ')
                  ])
                  initialAmount += temp.price
                }
              } else resultIntermediate.push(...["", "", "", ""])
              if (i < nbreSpents) {
                const temp = element.spents[i]
                if (temp.spentEmitter) {
                  initialAmount -= temp.amount
                  resultIntermediate.push(...[
                    element.date, temp.name, temp.spentEmitter.name, moneyFormat(temp.amount, 0, 3, ' '), moneyFormat(initialAmount, 0, 3, ' ')
                  ])
                } else {
                  initialAmount -= temp.cost
                  resultIntermediate.push(...[
                    element.date, temp.name, "", moneyFormat(temp.cost, 0, 3, ' '), moneyFormat(initialAmount, 0, 3, ' ')
                  ])
                }
              } else {
                resultIntermediate.push(...["",
                  "",
                  "",
                  "",
                  moneyFormat(initialAmount, 0, 3, ' ')
                ])
              }
              finalResult.push(resultIntermediate)
            }
          })
        }
        
        finalResult.push([
          "",
          "TOTAL ENTREES",
          "",
          moneyFormat(totalEntries, 0, 3, ' '),
          "",
          "TOTAL DEPENSES",
          "",
          moneyFormat(totalSpents, 0, 3, ' '),
          moneyFormat(initialAmount, 0, 3, ' ')
        ])
        finalResult.push([])
        
        return finalResult
        
      },
      jExcelRangeOptions() {
        const mergeCells = {
          A1: [9, 1],
          A2: [9, 1],
          A3: [2, 1],
          D3: [6, 1],
          A4: [2, 1],
          D4: [6, 1],
          A5: [9, 1],
          A6: [1, 2],
          B6: [3, 1],
          E6: [1, 2],
          F6: [3, 1],
          I6: [1, 2],
          A8: [4, 1],
          F8: [2, 1],
        }
        mergeCells['B' + this.dataRangeEntriesSpents.length + 1] = [2, 1]
        mergeCells['F' + this.dataRangeEntriesSpents.length + 1] = [2, 1]
        console.log(this.dataRangeEntriesSpents)
        return {
          data: this.dataRangeEntriesSpents,
          allowExport: true,
          columns: [
            {type: 'text', title: '', width: 130},
            {type: 'text', title: '', width: 250, align: 'left'},
            {type: 'text', title: '', width: 150},
            {type: 'numeric', title: '', width: 100, align: 'right'},
            {type: 'text', title: '', width: 130},
            {type: 'text', title: '', width: 300, align: 'left'},
            {type: 'text', title: '', width: 150, align: 'left'},
            {type: 'numeric', title: '', width: 100, align: 'right'},
            {type: 'numeric', title: '', width: 100, align: 'right'},
          ],
          mergeCells,
          style: {
            A1: 'background-color: orange;'
          },
        }
      },
      jExcelOptions() {
        return this.dataEntriesSpents.map(dataEntrySpent => {
          const mergeCells = {
            A1: [9, 1],
            A2: [9, 1],
            A3: [2, 1],
            D3: [6, 1],
            A4: [2, 1],
            D4: [6, 1],
            A5: [9, 1],
            A6: [1, 2],
            B6: [3, 1],
            E6: [1, 2],
            F6: [3, 1],
            I6: [1, 2],
            A8: [4, 1],
            F8: [2, 1],
          }
          mergeCells['B' + dataEntrySpent.length + 1] = [2, 1]
          mergeCells['F' + dataEntrySpent.length + 1] = [2, 1]
          return {
            data: dataEntrySpent,
            allowExport: true,
            columns: [
              {type: 'text', title: '', width: 130},
              {type: 'text', title: '', width: 250, align: 'left'},
              {type: 'text', title: '', width: 150},
              {type: 'numeric', title: '', width: 100, align: 'right'},
              {type: 'text', title: '', width: 130},
              {type: 'text', title: '', width: 300, align: 'left'},
              {type: 'text', title: '', width: 150, align: 'left'},
              {type: 'numeric', title: '', width: 100, align: 'right'},
              {type: 'numeric', title: '', width: 100, align: 'right'},
            ],
            mergeCells,
            style: {
              A1: 'background-color: orange;'
            },
          }
        })
      },
    },
    methods: {
      setOpenTab(data) {
        if (data.length >= 2)
          this.monthYearResume = 1
      },
      findResume() {
        this.submittedRangeMonth = true
        if (!(this.selectedBeginMonth != "" && this.selectedEndMonth != ""
          && moment(this.selectedEndMonth).isSameOrAfter(moment(this.selectedBeginMonth))) && this.modeSelected)
          return
        let rangeDate = {}
        if (this.modeSelected)
          rangeDate = {
            startDate: this.convertDate(this.selectedBeginMonth),
            endDate: this.convertDate(this.selectedEndMonth),
          }
        else
          rangeDate = {
            startDate: this.convertDate(this.currentMonth),
            endDate: this.convertDate(this.currentMonth),
          }
        this.dataReadyResume = true
        Api.get('/exploitation/capital/period-resume/', {
          exploitationId: this.exploitationId,
          ...rangeDate
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              for (let i = 0; i < this.resumeEntriesSpents.length; i++) {
                this.$refs[`spreadsheet${i}`][0].innerHTML = ""
              }
              this.$refs["rangeSpreadsheet"].innerHTML = ""
              this.resumeEntriesSpents = res.data.data
              this.monthYearResume = 1
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.submittedRangeMonth = false
            this.currentMonth = null
            this.selectedBeginMonth = null
            this.selectedEndMonth = null
          })
      },
      convertDate(dateE) {
        const date = new Date(dateE)
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const realMonth = month.toString().length == 2 ? month : '0' + month
        return realMonth + '-' + year
      },
      async exportFile() {
        
        this.fetchingDownloadResume = true
        const month = this.resumeEntriesSpents[0].month
        const year = this.resumeEntriesSpents[0].year
        const reL = this.resumeEntriesSpents.length
        let period = ""
        if (reL >= 2) {
          const monthL = this.resumeEntriesSpents[reL - 1].month
          const yearL = this.resumeEntriesSpents[reL - 1].year
          period = month + " " + year + " - " + monthL + " " + yearL
        } else period = month + " " + year
        
        let initialAmount = this.resumeEntriesSpents[0].initialAmount
        const entryColorStyle = {
          fill: {
            fgColor: {rgb: "a2a2ff"}
          },
          border: {
            top: {
              style: "thin",
              color: {rgb: "000000"}
            },
            left: {
              style: "thin",
              color: {rgb: "000000"}
            },
            right: {
              style: "thin",
              color: {rgb: "000000"}
            },
            bottom: {
              style: "thin",
              color: {rgb: "000000"}
            },
          },
          font: {
            sz: 11
          }
        }
        const spentColorStyle = {
          fill: {
            fgColor: {rgb: "ffd2ac"}
          },
          border: {
            top: {
              style: "thin",
              color: {rgb: "000000"}
            },
            left: {
              style: "thin",
              color: {rgb: "000000"}
            },
            right: {
              style: "thin",
              color: {rgb: "000000"}
            },
            bottom: {
              style: "thin",
              color: {rgb: "000000"}
            },
          },
          font: {
            sz: 11
          }
        }
        const remainingAmountColorStyle = {
          fill: {
            fgColor: {rgb: "40ff92"}
          },
          border: {
            top: {
              style: "thin",
              color: {rgb: "000000"}
            },
            left: {
              style: "thin",
              color: {rgb: "000000"}
            },
            right: {
              style: "thin",
              color: {rgb: "000000"}
            },
            bottom: {
              style: "thin",
              color: {rgb: "000000"}
            },
          },
        }
        const topLeftColorStyle = {
          fill: {
            fgColor: {rgb: "d0cfcf"}
          },
          border: {
            top: {
              style: "thin",
              color: {rgb: "000000"}
            },
            left: {
              style: "thin",
              color: {rgb: "000000"}
            },
            right: {
              style: "thin",
              color: {rgb: "000000"}
            },
            bottom: {
              style: "thin",
              color: {rgb: "000000"}
            },
          }
        }
        const blackColorStyle = {
          fill: {
            fgColor: {rgb: "000000"}
          }
        }
        const boldStyle = {
          font: {
            bold: true
          }
        }
        const styles = {
          headerTitle: {
            font: {
              sz: 13,
              bold: true,
            },
            alignment: {
              horizontal: "center"
            }
          },
          leftTextTile: {
            font: {
              sz: 12,
              bold: true,
            },
            alignment: {
              horizontal: "right"
            }
          },
          dateTextTitle: {
            alignment: {
              horizontal: "center",
              vertical: "center"
            }
          },
          periodTitle: {
            font: {
              sz: 12,
              bold: true,
              color: {rgb: "ff005f"}
            }
          },
          dateStyle: {
            alignment: {
              horizontal: "right"
            }
          },
          natureStyle: {
            alignment: {
              horizontal: "left"
            }
          },
          amountStyle: {
            alignment: {
              horizontal: "right"
            },
            font: {
              bold: true,
              name: "Cambria"
            }
          },
          ownerStyle: {
            font: {
              bold: true,
              italic: true,
              sz: 11
            },
            alignment: {
              horizontal: "left"
            }
          },
          
          resumeStyle: {
            fill: {
              fgColor: {rgb: "cdcdcd"}
            },
            font: {
              bold: true,
            }
          }
        };
        
        //Array of objects representing heading rows (very top)
        const heading_resume = [
          [{value: this.exploitationNam, style: styles.headerTitle}],
          [],
          [{value: 'Mois :', style: {...styles.leftTextTile, ...topLeftColorStyle}},
            {value: '', style: {...styles.leftTextTile, ...topLeftColorStyle}},
            {value: '', style: {...styles.leftTextTile, ...topLeftColorStyle}},
            {
              value: period,
              style: styles.periodTitle
            }],
          [{
            value: 'Fiche completée :',
            style: {...styles.leftTextTile, ...topLeftColorStyle}
          }, {
            value: '',
            style: {...styles.leftTextTile, ...topLeftColorStyle}
          },
            {
              value: '',
              style: {...styles.leftTextTile, ...topLeftColorStyle}
            }, {value: " ", style: {}}, {value: " ", style: {}},],
          [],
          [{value: 'Date', style: {...styles.dateTextTitle, ...entryColorStyle, ...boldStyle}},
            {
              value: 'Entrées',
              style: {...styles.dateTextTitle, ...entryColorStyle, ...boldStyle}
            },
            {
              value: '',
              style: {...styles.dateTextTitle, ...entryColorStyle, ...boldStyle}
            },
            {
              value: '',
              style: {...styles.dateTextTitle, ...entryColorStyle, ...boldStyle}
            },
            {value: 'Date', style: {...styles.dateTextTitle, ...spentColorStyle, ...boldStyle}},
            {
              value: 'Dépenses',
              style: {...styles.dateTextTitle, ...spentColorStyle, ...boldStyle}
            },
            {
              value: '',
              style: {...styles.dateTextTitle, ...spentColorStyle, ...boldStyle}
            },
            {
              value: '',
              style: {...styles.dateTextTitle, ...spentColorStyle, ...boldStyle}
            },
            {
              value: 'Solde',
              style: {...styles.dateTextTitle, ...remainingAmountColorStyle, ...boldStyle}
            },
          ],
          [{value: ''},
            {
              value: 'Nature',
              style: {...styles.dateTextTitle, ...entryColorStyle, ...boldStyle}
            },
            {
              value: 'Par',
              style: {...styles.dateTextTitle, ...entryColorStyle, ...boldStyle}
            },
            {
              value: 'Montant',
              style: {...styles.dateTextTitle, ...entryColorStyle, ...boldStyle}
            },
            {value: '',},
            {
              value: 'Nature',
              style: {...styles.dateTextTitle, ...spentColorStyle, ...boldStyle}
            },
            {
              value: 'Par',
              style: {...styles.dateTextTitle, ...spentColorStyle, ...boldStyle}
            },
            {
              value: 'Montant',
              style: {...styles.dateTextTitle, ...spentColorStyle, ...boldStyle}
            },
            {
              value: '',
              style: {...styles.dateTextTitle, ...remainingAmountColorStyle, ...boldStyle}
            },
          ],
        ];
        
        //Here you specify the export structure
        const specification_resume = {
          entry_date: { // <- the key should match the actual data key
            displayName: " ", // <- Here you specify the column header
            headerStyle: blackColorStyle,
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle}
              else return {...entryColorStyle, ...styles.dateStyle}
            }, // <- Cell style
            width: 140 // <- width in pixels
          },
          entry_nature: { // <- the key should match the actual data key
            displayName: " ", // <- Here you specify the column header
            headerStyle: blackColorStyle,
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle}
              else return {...entryColorStyle, ...styles.natureStyle}
            }, // <- Cell style
            width: 270 // <- width in pixels
          },
          entry_emitter: { // <- the key should match the actual data key
            displayName: " ", // <- Here you specify the column header
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle}
              else return {...entryColorStyle, ...styles.ownerStyle}
            }, // <- Cell style
            width: 150 // <- width in pixels
          },
          entry_amount: { // <- the key should match the actual data key
            displayName: " ", // <- Here you specify the column header
            headerStyle: blackColorStyle,
            cellFormat: function (value, row) {
              return value.toString()
            },
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle, ...styles.amountStyle}
              else return {...entryColorStyle, ...styles.amountStyle}
            }, // <- Cell style
            width: 100 // <- width in pixels
          },
          spent_date: { // <- the key should match the actual data key
            displayName: "01 " + month + " " + year, // <- Here you specify the column header
            headerStyle: {...spentColorStyle, ...styles.dateStyle},
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle}
              else return {...spentColorStyle, ...styles.dateStyle}
            }, // <- Cell style
            width: 140 // <- width in pixels
          },
          spent_nature: { // <- the key should match the actual data key
            displayName: "Solde initial", // <- Here you specify the column header
            headerStyle: {...spentColorStyle, ...styles.natureStyle, ...boldStyle},
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle}
              else return {...spentColorStyle, ...styles.natureStyle}
            }, // <- Cell style
            width: 270 // <- width in pixels
          },
          spent_emitter: { // <- the key should match the actual data key
            displayName: " ", // <- Here you specify the column header
            headerStyle: {...spentColorStyle, ...styles.ownerStyle},
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle}
              else return {...spentColorStyle, ...styles.ownerStyle}
            }, // <- Cell style
            width: 150 // <- width in pixels
          },
          spent_amount: { // <- the key should match the actual data key
            displayName: 0, // <- Here you specify the column header
            headerStyle: {...spentColorStyle, ...styles.amountStyle},
            cellFormat: function (value, row) {
              return value.toString()
            },
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle, ...styles.amountStyle}
              else return {...spentColorStyle, ...styles.amountStyle}
            }, // <- Cell style
            width: 100 // <- width in pixels
          },
          remaining_amount: { // <- the key should match the actual data key
            displayName: moneyFormat(initialAmount, 0, 3, ' '),
            headerStyle: {...styles.amountStyle, ...remainingAmountColorStyle},
            cellFormat: function (value, row) {
              return value.toString()
            },
            cellStyle: function (value, row) {
              if (row.resume) return {...styles.resumeStyle, ...styles.amountStyle}
              else return {...remainingAmountColorStyle, ...styles.amountStyle}
            }, // <- Cell style
            width: 100 // <- width in pixels
          },
        }
        
        // The data set should have the following shape (Array of Objects)
        // The order of the keys is irrelevant, it is also irrelevant if the
        // dataset contains more fields as the report is build based on the
        // specification provided above. But you should have all the fields
        // that are listed in the report specification
        
        const dataset_resume = []
        let totalEntries = 0
        let totalSpents = 0
        for (let i = 0; i < this.resumeEntriesSpents.length; i++) {
          const resumeEntrySpent = this.resumeEntriesSpents[i]
          totalEntries += resumeEntrySpent.totalEntries
          totalSpents += resumeEntrySpent.totalSpents
          resumeEntrySpent.resume.forEach(element => {
            const nbreSpents = element.spents.length
            const nbreEntries = element.entries.length
            const maxN = Math.max(nbreSpents, nbreEntries)
            for (let i = 0; i < maxN; i++) {
              let resultIntermediate = {}
              if (i < nbreEntries) {
                const temp = element.entries[i]
                if (temp.amount) {
                  resultIntermediate = {
                    entry_date: element.date,
                    entry_nature: temp.paymentMode.name + " " + temp.entryReceiver.name,
                    entry_emitter: temp.entryEmitter.name,
                    entry_amount: moneyFormat(temp.amount, 0, 3, ' '),
                  }
                  initialAmount += temp.amount
                } else {
                  resultIntermediate = {
                    entry_date: element.date,
                    entry_nature: temp.name,
                    entry_emitter: temp.user.name,
                    entry_amount: moneyFormat(temp.price, 0, 3, ' '),
                  }
                  initialAmount += temp.price
                }
              } else
                resultIntermediate = {
                  entry_date: "",
                  entry_nature: "",
                  entry_emitter: "",
                  entry_amount: "",
                }
              if (i < nbreSpents) {
                const temp = element.spents[i]
                if (temp.spentEmitter) {
                  initialAmount -= temp.amount
                  resultIntermediate = {
                    ...resultIntermediate,
                    spent_date: element.date,
                    spent_nature: temp.name,
                    spent_emitter: temp.spentEmitter.name,
                    spent_amount: moneyFormat(temp.amount, 0, 3, ' '),
                    remaining_amount: moneyFormat(initialAmount, 0, 3, ' '),
                  }
                } else {
                  initialAmount -= temp.cost
                  resultIntermediate = {
                    ...resultIntermediate,
                    spent_date: element.date,
                    spent_nature: temp.name,
                    spent_emitter: "",
                    spent_amount: moneyFormat(temp.cost, 0, 3, ' '),
                    remaining_amount: moneyFormat(initialAmount, 0, 3, ' '),
                  }
                }
              } else {
                resultIntermediate = {
                  ...resultIntermediate,
                  spent_date: "",
                  spent_nature: "",
                  spent_emitter: "",
                  spent_amount: "",
                  remaining_amount: moneyFormat(initialAmount, 0, 3, ' '),
                }
              }
              dataset_resume.push(resultIntermediate)
            }
          })
        }
        dataset_resume.push({
          entry_date: "",
          entry_nature: "",
          entry_emitter: "",
          entry_amount: "",
          spent_date: "",
          spent_nature: "",
          spent_emitter: "",
          spent_amount: "",
          remaining_amount: "",
        })
        
        dataset_resume.push({
          entry_date: "",
          entry_nature: "TOTAL ENTREES",
          entry_emitter: "",
          entry_amount: moneyFormat(totalEntries, 0, 3, ' '),
          spent_date: "",
          spent_nature: "TOTAL DEPENSES",
          spent_emitter: "",
          spent_amount: moneyFormat(totalSpents, 0, 3, ' '),
          remaining_amount: moneyFormat(initialAmount, 0, 3, ' '),
          resume: true
        })
        
        // Define an array of merges. 1-1 = A:1
        // The merges are independent of the data.
        // A merge will overwrite all data _not_ in the top-left cell.
        const merges_resume = [
          {start: {row: 1, column: 1}, end: {row: 1, column: 9}},
          {start: {row: 2, column: 1}, end: {row: 2, column: 9}},
          
          {start: {row: 3, column: 1}, end: {row: 3, column: 2}},
          {start: {row: 3, column: 4}, end: {row: 3, column: 9}},
          {start: {row: 4, column: 1}, end: {row: 4, column: 2}},
          {start: {row: 4, column: 4}, end: {row: 4, column: 9}},
          
          {start: {row: 5, column: 1}, end: {row: 5, column: 9}},
          {start: {row: 6, column: 1}, end: {row: 7, column: 1}},
          {start: {row: 6, column: 5}, end: {row: 7, column: 5}},
          {start: {row: 6, column: 9}, end: {row: 7, column: 9}},
          
          {start: {row: 6, column: 2}, end: {row: 6, column: 4}},
          {start: {row: 6, column: 6}, end: {row: 6, column: 8}},
          
          {start: {row: 8, column: 1}, end: {row: 8, column: 4}},
        ]
        
        // Create the excel report.
        // This function will return Buffer
        const report = excelE.buildExport(
          [ // <- Notice that this is an array. Pass multiple sheets to create multi sheet report
            {
              name: period, // <- Specify sheet name (optional)
              heading: heading_resume, // <- Raw heading array (optional)
              merges: merges_resume, // <- Merge cell ranges
              specification: specification_resume, // <- Report specification
              data: dataset_resume // <-- Report data
            }
          ]
        );
        
        const fileName = (this.exploitationNam + '_' + period + '_' + (new Date().getTime()) + '.xlsx').replace(/\s/g, '');
        await save(report, fileName)
        this.fetchingDownloadResume = false
      }
    },
  }
</script>

<style scoped>

</style>
<style>
  .date-popover{
    z-index: 100 !important;
  }
  
  .vue-monthly-picker .input{
    color: #7b7b7b !important;
    font-size: .9rem !important;
  }
  
  .info-danger-month i{
    color: red !important;
  }
</style>
